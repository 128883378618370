<template>
  <div class="project-sales-main-section row p-2">
    <div class="col-12 md-col-8">
      <!-- Project Info -->
      <div class="project-info row">
        <img
          v-if="_.get(project, 'mainPhoto[0]')"
          class="project-img"
          :src="$getFileURL(project.mainPhoto[0])"
          :alt="project.name"
          @error="$setAltPhoto"
          @click="projectMainPhotoIndex = 0"
        />
        <div v-else class="skeleton project-img"></div>

        <div v-if="reservation" class="my-2 md-my-0 md-mx-3">
          <h4 class="mb-2">
            <span class="plot">{{ reservation.unitPlot }}</span>
            {{ unit.street }}
          </h4>

          <p class="price mb-1">RM {{ price }}</p>
          <p>
            <span class="font-bold mr-1">Project:</span>
            {{ project.name }}
          </p>
          <p>
            <span class="font-bold mr-1">Unit Type:</span>
            {{ unitType.name }}
          </p>
          <p>
            <span class="font-bold mr-1">Phase:</span>
            {{ unit.phase.name }}
          </p>

          <!-- SPA Deadline-->
          <div
            v-if="salesData.projectPurchaseLawyer && !isSPASigned"
            class="col-12 bg-grey-100 p-2 mt-1"
          >
            <p :class="['d-inline-block', { 'fg-danger': isSPAOverdue }]">
              <span class="font-bold mr-1">SPA Deadline:</span>
              {{
                $moment(
                  salesData.projectPurchaseLawyer.salesPurchaseAgreementDueDate
                ).format("DD-MM-YYYY")
              }}
            </p>
            <fd-button
              v-if="
                canExtendSPADueDate &&
                salesData.projectPurchaseLawyer &&
                !this.isSPASigned
              "
              class="xs flat main p-1 ml-3"
              @click="$emit('extendSPADueDate')"
              >Extend</fd-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 md-col-4 md-mt-0">
      <div class="row md-justify-content-end">
        <!-- Status -->
        <div class="md-col-12 md-d-flex md-justify-content-end">
          <project-sale-status
            v-if="salesData.currentPhase"
            :status="salesData.currentPhase"
            class="d-block mr-1 md-mr-0 md-mb-1"
          ></project-sale-status>
        </div>
        <!-- Date -->
        <div class="date">
          {{ $moment(salesData.createdAt).format("LL") }}
        </div>
      </div>
    </div>

    <!-- Cancel Sales -->
    <div
      v-if="canCancel && isCanceleable"
      class="col-12 d-flex justify-content-end mt-2"
    >
      <fd-button class="bordered danger" @click="$emit('cancelProjectSales')">
        Cancel Sale
      </fd-button>
    </div>
  </div>
</template>

<script>
import { projectSalesPhase as STATUS } from "@/enums";
import { projectLawyerClientStatus as LEGAL_STATUS } from "@/enums";
import { numberWithCommas } from "@/utils/string";

export default {
  components: {
    ProjectSaleStatus: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/ProjectSaleStatus"
      )
  },
  mixins: [],
  props: {
    canCancel: {
      type: Boolean,
      default: false
    },
    canExtendSPADueDate: {
      type: Boolean,
      default: false
    },
    salesData: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      projectMainPhotoIndex: null,
      numberWithCommas
    };
  },
  computed: {
    isCanceleable() {
      return (
        this.salesData.currentPhase !== STATUS.CANCELLED &&
        this.salesData.currentPhase !== STATUS.VACANT_POSSESSION_NOTICE &&
        this.salesData.currentPhase !== STATUS.COMPLETED
      );
    },
    isSPASigned() {
      let SPAStatus = this.salesData.projectPurchaseLawyer
        ?.salesPurchaseAgreementStatus;
      return (
        SPAStatus === LEGAL_STATUS.SPA_SIGNED ||
        SPAStatus === LEGAL_STATUS.SPA_STAMP_DUTY_PAID
      );
    },
    isSPAOverdue() {
      let lawyer = this.salesData.projectPurchaseLawyer;
      return lawyer
        ? this.$moment(lawyer.salesPurchaseAgreementDueDate).isBefore(
            this.$moment()
          )
        : false;
    },
    reservation() {
      return this.salesData.projectPurchaseReservation;
    },
    project() {
      return this.salesData.project;
    },
    unitType() {
      return this.salesData.projectUnitType;
    },
    unit() {
      return this.salesData.projectUnit;
    },
    price() {
      return numberWithCommas(
        parseInt(this.reservation.salesPurchaseAgreementPrice)
      );
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.project-sales-main-section {
  .project-img {
    @include image(cover);
    width: 260px;
    height: 180px;
    border-radius: 5px;

    @media #{$breakpoint-down-sm} {
      width: 100%;
      height: 100%;
    }
  }
  .plot {
    color: $color-accent2;
    padding: 4px 8px;
    border: solid 1px $color-accent2;
    border-radius: 5px;
    font-size: 16px;
  }
  .price {
    font-size: 18px;
  }
  .date {
    background: lighten($color-secondary, 45);
    color: $color-secondary;
    border-radius: 50px;
    width: fit-content;
    padding: 6px 16px;
    height: fit-content;
  }
}
</style>
